import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import {BannerAds,GridAds } from '../components/AdsComponent';

const Tags = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchTags = async () => {
    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

    try {
      await new Promise(resolve => setTimeout(resolve, 500));

      const response = await axios.get('/c.json');
      const data = response.data.tags;

      if (data) {
        setTags(data);
      } else {
        console.error('Failed to fetch tags:', response.data);
        setError(true);
      }
    } catch (error) {
      console.error('Error fetching tags:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  if (loading) {
    return <LoadingText />;
  }

  if (error) {
    return <ErrorRetry title={'标签'} onClick={fetchTags} />;
  }

  return (
    <>
      <BannerAds />
      <GridAds/>
      <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4">所有标签列表</div>
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-3 xs:gap-3 xs:text-xs s:text-sm">
        {tags.map((tag, index) => (
          <Link key={index} to={`/tags/${tag}/`} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">
            {tag}
          </Link>
        ))}
      </div>
    </>
  );
};

export default Tags;
